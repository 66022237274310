<template>
  <el-dialog :close-on-click-modal="false" :title="isEdit ? '编辑角色' : '新增角色'" :visible.sync="show" width="500px" class="selfInputBox">
    <el-form ref="form" label-width="80px">
      <el-form-item label="角色名称" prop="">
        <el-input v-model="upDatavalue.name" />
      </el-form-item>

      <el-form-item label="角色权限" prop="">
        <!-- <el-input v-model="upDatavalue.menu_ids" /> -->

        <div class="boxThree">
          <el-tree
            :data="power"
            show-checkbox
            default-expand-all
            node-key="id"
            ref="tree"
            highlight-current
            :props="defaultProps"
            :default-expanded-keys="selectArray"
            :default-checked-keys="selectArray"
            @check-change="handleCheckChange"
          >
          </el-tree>
        </div>
      </el-form-item>

      <!-- type: "",//类型： 1管理员 2录入人 -->
      <el-form-item label="类型" prop="">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-radio v-model="type" :label="1">管理员</el-radio>
        <el-radio v-model="type" :label="2">录入人</el-radio>
      </el-form-item>

      <el-form-item label="显示顺序" prop="">
        <el-input v-model="upDatavalue.sort" />
      </el-form-item>
    </el-form>

    <span slot="footer">
      <div class="buttons">
        <el-button type="primary" @click="save">保存</el-button>
        <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
      </div>
    </span>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    // value: {
    //     type: Object,
    //     required: true
    // }
  },
  data() {
    return {
      show: false,

      upDatavalue: {},

      isEdit: false,

      power: [],
      selectArray: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },

      type: 1
    }
  },

  watch: {
    show(value) {
      if (this.show) {
        // if (this.power.length == 0) {
        this.getPower()
        // }
      } else {
        this.$nextTick(() => {
          this.upDatavalue = {}
          this.isEdit = false

          this.selectArray = []
          this.power = []

          this.type = 1
        })
      }
    }
  },

  methods: {
    open() {
      this.show = true
    },

    getPower() {
      this.$http
        .get('/api/menu/tree')
        .then((res) => {
          if (res.code == 200) {
            this.power = res.data
          }

          if (res.code != 200) {
            //请求错误
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    edit(val) {
      this.$http
        .get('/api/role/' + val.id)
        .then((res) => {
          if (res.code == 200) {
            console.log('!!!!!!!!!!!!!!!!!!', res.data)

            this.type = res.data.type

            this.isEdit = true

            this.show = true

            this.upDatavalue = {
              ...res.data
            }

            this.selectArray = []

            if (this.upDatavalue.menu_ids) {
              this.selectArray = this.upDatavalue.menu_ids
              // .split(',').map(x => {
              //     return Number(x)
              // })
            } else {
              this.selectArray = []
            }
          }
          if (res.code != 200) {
            //请求错误
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },

    handleCheckChange(data, checked, indeterminate) {
      // console.log(data, checked, indeterminate);

      if (checked) {
        this.selectArray.push(data.id)
      } else {
        this.selectArray.forEach((item, index, arr) => {
          if (item === data.id) {
            this.selectArray.splice(index, 1)
          }
        })
      }
    },

    // formatTree(tree, val) {
    //     if (!tree || !tree.length) return;
    //     for (const node of tree) {
    //         node.level = val
    //         if (node.children && node.children.length) {
    //             this.formatTree(node.children, val + 1)
    //         } else {
    //             // this.selectArray.unshift(node.id)
    //             // this.selectArray = Object.values(this.selectArray)
    //         }
    //     }
    //     console.log("++++++++++++++++++++++++++++++", this.selectArray);
    //     return tree
    // },

    save() {
      //   var a = this.$refs.tree.getHalfCheckedKeys()
      // var b = this.$refs.tree.getCheckedKeys()
      // var c = [...a, ...b]

      //   console.log(a)

      //   this.selectArray.concat(a)

      //   console.log(this.selectArray)

      if (this.type == null) {
        this.$message.error('请选择角色类型')
        return
      }

      var a = this.$refs.tree.getCheckedKeys()
      var b = this.$refs.tree.getHalfCheckedKeys()
      //   console.log(a)
      //   console.log(b)

      var c = [...a, ...b]
      console.log(c)

      if (this.isEdit) {
        // 更新

        var a = {
          name: this.upDatavalue.name,
          // menu_ids: this.upDatavalue.menu_ids,
          // menu_ids: this.selectArray, //.toString()
          //   menu_ids: this.selectArray.concat(a), //.toString()
          menu_ids: c, //.toString()
          sort: this.upDatavalue.sort,

          type: this.type
        }

        this.$http
          .put('/api/role/' + this.upDatavalue.id, a)
          .then((res) => {
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)
            if (res.code != 200) {
              //请求错误
              this.$message.error(res.msg)
            }
            if (res.code == 200) {
              this.show = false
              this.$emit('refresh')
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        // 新建

        var a = {
          name: this.upDatavalue.name,
          // menu_ids: this.upDatavalue.menu_ids,
          // menu_ids: this.selectArray, //.toString()
          //   menu_ids: this.selectArray.concat(a), //.toString()
          menu_ids: c, //.toString()
          sort: this.upDatavalue.sort,

          type: this.type
        }

        this.$http
          .post('/api/role', a)
          .then((res) => {
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', res)

            if (res.code == 200) {
              this.show = false
              this.$emit('refresh')
            }

            if (res.code != 200) {
              //请求错误
              this.$message.error(res.msg)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }
}
</script>


<style scoped>
.boxThree {
  /*  border: 1px red dotted;
        box-sizing: content-box; */
  height: 280px;
  width: 280px;
  overflow: scroll;
  margin-left: 20px;
}
</style>